.text-animate {
   display: inline;
   opacity: 0;
   animation: bounceIn;
   animation-duration: 1s;
   animation-delay: 1s;
   animation-fill-mode: forwards;
}
 
.text-animate-hover {
   display: inline;
   animation-fill-mode: both;
 
   &:hover {
     animation: rubberBand 1s;
     color: rgba(0, 51, 102, 0.9);
   }
}
 
@for $i from 1 through 70 {
   .text-animate._#{$i} {
      animation-delay: #{$i / 10}s;
   }
}
