.project {
    padding: 80px 0;
    position: relative;
    background-image: url('../../assets/img/flower-background-projects.png');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }

.project-bx {
  background-image: url('../../assets/img/blank-background-darker.jpg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 64px;
  padding: 60px 50px 0 50px;
}

  .project h2 {
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    color:rgba(0, 0, 0, 0.8);
  }
  .project p.desc {
    color: rgba(20, 22, 20, 0.7);
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px auto 30px auto;
    text-align: center;
    width: 56%;
  }
  .project .nav.nav-pills {
    width: 72%;
    margin: 0 auto;
    border-radius: 50px;
    background-color: rgb(255 255 255 / 10%);
    overflow: hidden;
  }
  .project .nav.nav-pills .nav-item {
    width: 33.33333%;
  }
  .project .nav.nav-pills .nav-link {
    background-color: transparent;
    border-radius: 0;
    padding: 17px 0;
    color: #fff;
    width: 100%;
    font-size: 17px;
    letter-spacing: 0.8px;
    font-weight: 500;
    position: relative;
    transition: 0.3s ease-in-out;
    text-align: center;
    z-index: 0;
  }
  .project .nav.nav-pills .nav-link::before {
      content: "";
      position: absolute;
      width: 0;
      height: 100%;
      background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
      top: 0;
      left: 0;
      z-index: -1;
      transition: 0.3s ease-in-out;
  }
  .project .nav.nav-pills .nav-link.active::before {
      width: 100% !important;
  }
  .project .nav.nav-pills .nav-link.active {
      border: 1px solid rgba(255, 255, 255, 1);
  }
  .nav-link#projects-tabs-tab-first {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 55px 0px 0px 55px;
  }
  .nav-link#projects-tabs-tab-second {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  .nav-link#projects-tabs-tab-third {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0 55px 55px 0;
  }
  
  .background-image-right {
    top: 20%;
    position: absolute;
    bottom: 0;
    width: 35%;
    right: 0;
    z-index: -4;
  }