.about {
  background-image: url('../../assets/img/flower-background.jpg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;      
}
  .about-bx {
    background-image: url('../../assets/img/blank-background-darker.jpg');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    /* background: rgb(212, 197, 170); */
    border-radius: 64px;
    text-align: center;
    padding: 60px 50px;
    margin-top: 10px;
  }

  
  .about h2 {
    color:rgba(0, 0, 0, 0.8);
    font-size: 45px;
    font-weight: 700;
  }
  .about p {
      color: rgba(20, 22, 20, 0.7);
      font-size: 18px;
      letter-spacing: 0.8px;
      line-height: 1.5em;
      white-space: normal;  
      word-wrap: break-word;  
      max-width: 90%;  
      margin-left: auto;
      margin-right: auto;
  }
  .about-slider {
    width: 80%;
    margin: 0 auto;
    position: relative;
  }
  .about-slider .item img {
    width: 50%;
    margin: 0 auto 15px auto;
  }
  .background-image-left {
    top: 28%;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: -4;
  }

.icon-instruction {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

.hover-instruction {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  color: rgba(0, 0, 0, 0.7);
}
  