.magic-container {  
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden; 
}

.rose {
  position: 'absolute';
  width: 35%;
  height: auto;
  z-index: 99;
}

#tiles {
  display: flex;
  justify-content: center;
  margin-top: 6%;
}

.tile {
  display: grid;
  place-items: center;
  width: 38vmin;
  aspect-ratio: 1;
  background-color: rgb(31, 41, 55);
  border-radius: 6vmin;
  box-shadow: 0vmin 3vmin 6vmin rgb(0 0 0 / 25%),
    inset 0vmin 0.5vmin 1vmin rgb(255 255 255 / 15%);
  position: relative;
  overflow: hidden;
}

.tile:nth-child(1) {
  rotate: 3deg;
  z-index: 3;
}

.tile:nth-child(2) {
  rotate: -2deg;
  z-index: 2;
}

.tile:nth-child(3) {
  rotate: 5deg;
  z-index: 1;
}

.tile:is(:nth-child(2), :nth-child(3)) {
  margin-left: -6vmin;
}

.tile > i {
  font-size: 15vmin;
  color: rgb(255 255 255 / 10%);
}

.tile > img {
  height: 100%;
  aspect-ratio: 1;
  position: absolute;
  left: 0px;
  top: 0px;
  object-fit: cover;
  opacity: var(--opacity);
  filter: blur(calc(var(--blur) * 10px));
}