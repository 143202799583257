.experience {
    margin-top: 0;
    padding: 260px 0 100px 0;
    background-image: url('../../assets/img/flower-background-flipped.jpg');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;      
    /* height: 100vh;   */
    padding: 100px 20px;
    text-align: center;
}

.experience-company {
    color: rgba(0, 0, 0, 0.8);
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 40px;
}

.experience-section-title {
    color:rgba(0, 0, 0, 0.7);
    background-image: url('../../assets/img/blank-background-darker.jpg');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 40px;
    padding: 15px 30px; 
    border-radius: 50px; 
    display: inline-block; 
    text-align: center;
}

.experience-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.experience-item {
    background-color: white;
    padding: 20px;
    border-radius: 30px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.experience-item:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.experience-image {
    width: 100%;  
    height: 100px; 
    object-fit: contain; 
    margin: 10px; 
    display: block;
}

.experience-company {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center; 
}

.experience-title {
    color:black;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: center; 
}

.experience-date {
    font-size: 14px;
    color: #000000;
    background-color: #ccc8b1; 
    padding: 8px 15px; 
    border-radius: 50px; 
    display: inline-block; 
    margin-bottom: 15px; 
    text-align: center; 
}

.experience-description {
    list-style-type: disc; 
    padding-left: 20px;    
    text-align: left;      
    margin: 0 auto;        
    max-width: 90%;        
    font-size: 16px;
    color: #555;
}

.experience-description li {
    margin-bottom: 10px; 
}
