.banner {
    margin-top: 0;
    padding: 260px 0 100px 0;
    background-image: url('../../assets/img/spring-background-2.jpg');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
}

.tagline-container {
    width: 100%; 
    display: flex;
    margin-bottom: 16px;
}

.banner .tagline {
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 8px 10px;
    background: linear-gradient(90.21deg, rgba(0, 51, 102, 0.8) -5.91%, rgba(255, 138, 128, 0.8) 111.58%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 20px;
    margin-bottom: 16px;
    display: inline-block;
    text-align: left; /* Default state */
}

.banner h1 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 57px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: block;
    word-break: break-word;
    text-align: left; /* Default state */
}

.banner p {
    color: rgba(20, 26, 19, 0.6);
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 96%;
    text-align: left; /* Default state */
}

.banner button {
    color: rgba(0, 51, 102, 0.8);
    font-weight: 700;
    font-size: 20px;
    margin-top: 60px;
    letter-spacing: 0.8px;
    display: flex;
    align-items: center;
    text-align: left; /* Default state */
}

.banner button svg {
    font-size: 25px;
    margin-left: 10px;
    transition: 0.3s ease-in-out;
    line-height: 1;
}

.banner button:hover svg {
    margin-left: 25px;
}

.bouncing {
    animation: updown 3s linear infinite;
}

@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}

.txt-rotate > .wrap {
    border-right: 0.08em solid #666;
}

h2 {
    color: #8d8d8d;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards; 
}

.logo-img { 
    margin-left: 20px;
    margin-bottom: 15px;
    opacity: 0;
    width: 50px;
    height: auto;
    animation: rotateIn 1s linear both;
    animation-delay: 0.7s;
}

.banner a {
    color: inherit;
    text-decoration: none; 
}

@media (max-width: 1200px) {
    .banner .col-md-6 {
        display: none;
    }

    .banner .col-md-6:first-child {
        display: block;
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
    }

    .tagline-container {
        justify-content: center; 
    }

    .banner h1,
    .banner p,
    .banner .tagline,
    .banner button {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .banner .tagline {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .banner .aligh-items-center {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
