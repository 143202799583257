.projects-slider-wrapper {
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    left: -4vw; 
    position: relative;
}

.projects-slider-container {
    height: 60vh;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.card {
    width: 6vw;
    border-radius: .75rem;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;
    border-radius: 2rem;
    margin: 0 10px;
    display: flex;
    align-items: flex-end;
    transition: .6s cubic-bezier(.28,-0.03,0,.99);
    box-shadow: 0px 10px 30px -5px rgba(0,0,0,0.8);
    transition: width 0.6s cubic-bezier(.28,-0.03,0,.99);
    position: relative;
}

.card.active {
    width: 20vw;

    .description {
        opacity: 1 !important;
        transform: translateY(0) !important;
    }
}

.card .row {
    color: white;
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    bottom: 15px;
    width: 100%;
    justify-content: flex;
}

.card .row .icon {
    background: rgba(0, 51, 102, 1);
    color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    font-size: larger;
}

.card .row .description {
    color:rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    height: 80px;
    width: 75%;
    opacity: 0;
    transform: translateY(30px);
    transition-delay: .3s;
    transition: all .3s ease;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    -webkit-text-stroke: 1px black;
}

.description h4 {
    text-transform: uppercase;
    font-size: 30px;
    text-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
}